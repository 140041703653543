<template>
    <Header :title="name" :backLinkText="$tc('back_to', 1) + ' ' + $tc('client', 2)" :backLinkURL="{ name: 'clients' }" />

    <section class="mt-10">
        <TabMenu :links="menuLinks" />
    </section>

    <section class="mt-10">
        <router-view />
    </section>
</template>

<script>
    import TabMenu from '@/components/general/TabMenu';

    export default {
        data() {
            return {
                menuLinks: [
                    { to: { name: 'clientOverview' }, display_name: this.$tc('overview', 1) },
                    { to: { name: 'clientEans' }, display_name: this.$tc('ean', 2) },
                    { to: { name: 'clientProducts' }, display_name: this.$tc('product', 2) },
                    { to: { name: 'clientContractFormulas' }, display_name: this.$tc('contract_formula', 2) },
                    { to: { name: 'clientInvoices' }, display_name: this.$tc('invoice', 2) },
                    { to: { name: 'clientConsumptions' }, display_name: this.$tc('consumptions', 2) },
                    { to: { name: 'clientSolarOffers' }, display_name: this.$tc('solar', 2) },
                ],
            };
        },
        components: {
            TabMenu,
        },
        created() {
            this.$store.dispatch('clients/fetchClient', this.$route.params.client_id);
        },
        computed: {
            name() {
                return `${this.$store.getters['clients/name']} - ${this.$store.getters['clients/enterpriseNumber']}`;
            },
        },
    };
</script>
